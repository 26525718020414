import { createSelector } from '@reduxjs/toolkit';

const getTestersState = (state) => state.testers;

// used for paginated results
export const getTestersRawData = createSelector(getTestersState, (testers) => testers.rawData || []);

// used for single result
export const getTesterSelector = createSelector(getTestersRawData, (testers) => (testerId) => testers.find((tester) => tester?.id?.toString() === testerId?.toString()));

export const getTotalTesters = createSelector(getTestersState, (state) => state.total);
